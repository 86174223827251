<template>
  <div class="py-5 text-center text-md-start">
    <h1 class="title text-center text-capitalize">
      <div v-if="this.$store.getters.lan">Personal Foreign assets declaration</div>
      <div v-else>海外资产申报</div>
    </h1>
    <div class="title fs-5">
      <div v-if="this.$store.getters.lan">
          <p>According to Canadian Income Tax Act, all Canadian tax residents whose total assets outside of Canada exceed $100,000 need to declare to the Canada Revenue Agency (CRA).Canadian tax residents need to declare foreign assets/foreign companies in the following four situations:</p>
          <ol>
              <li class="ps-1 ms-3 pb-2">The initial cost of foreign assets is above 100,000 Canadian dollars.</li>
              <li class="ps-1 ms-3 pb-2">There are affiliated companies that hold more than 100,000 Canadian dollars overseas.</li>
              <li class="ps-1 ms-3 pb-2">Transfer or loan to overseas trust.</li>
              <li class="ps-1 ms-3 pb-2">Accepts distribution or loans through the overseas trust.</li>
          </ol>
          <p>There are seven categories in foreign assets: cash and deposits outside Canada (including bank deposits, money orders, commercial paper; overseas private companies or stocks of listed companies); overseas claims, such as debts owed by others, bonds and loans of companies and governments; Overseas trust equity; overseas real estate and other overseas investment, such as precious metals, gold and silver bills, futures contracts, and overseas intangible assets.</p>
          <p>New immigrants don’t need to report foreign assets in the first year, but they must declare every year from the second year onwards. The cost of assets is calculated at the capital market price on the day of the immigration landing. It is worth mentioning that Canada does not levy taxes on “assets” themselves (except for corporate capital taxes), but on the “income” generated by these assets. To declare foreign assets, form T1135 is required.</p>
      </div>
      <div v-else><p>
         <p>根据加拿大的收入税法要求，所有加拿大的税务居民的加拿大境外的资产总值超过10万加元就必须向加拿大税务局（CRA）申报。加拿大的税务居民在下列四种情况下，需要申报海外资产/海外公司的情况：</p>
          <ol>
              <li class="ps-1 ms-3 pb-2">海外资产成本价在10万加元以上；</li>
              <li class="ps-1 ms-3 pb-2">在海外有持股超过10万加币以上的附属企业；</li>
              <li class="ps-1 ms-3 pb-2">转移或者贷款给海外信托；</li>
              <li class="ps-1 ms-3 pb-2">由海外信托接受分配或者借款。</li>
          </ol>
          <p>海外资产包括七类：加拿大境外的现金和存款（包括银行存款、汇票、商业票据；海外私人公司或者上市公司的股票）；海外的债权，如他人的欠款、公司和政府的债券、贷款；海外信托权益；海外的房地产和其他海外资，如贵金属、金银票据、期货合同和海外的无形资产等。</p>
          <p>新移民第一年可以免报，但从第二年开始就必须每年申报。资产成本以移民登陆那天的资本市场价格计算。值得一提的是，加拿大并不对“资产”本身征税（企业资本税除外），而是对这些资本产生的“收入”征税。申报海外资产需填写T1135表格。</p>
    </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>